import { Box, CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useState } from 'react';
import { useOnce } from '../../utils/Utils';

interface Props<T> {
  loadData(): Promise<T>;
  children(data: T): JSX.Element;
}

type State<T> =
  | {
      status: 'SUCCESS';
      data: T;
    }
  | {
      status: 'LOADING';
    }
  | {
      status: 'ERROR';
      error: { message: string };
    };

export function LoadingGuard<T>(props: Props<T>): JSX.Element {
  const [state, setState] = useState<State<T>>({ status: 'LOADING' });

  useOnce(() => {
    props
      .loadData()
      .then(data => setState({ data, status: 'SUCCESS' }))
      .catch(error => setState({ error, status: 'ERROR' }));
  });

  switch (state.status) {
    case 'SUCCESS':
      return props.children(state.data);
    case 'LOADING':
      return (
        <Box display={'flex'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      );
    case 'ERROR':
      return (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Something went wrong :(
          <div>{JSON.stringify(state.error)}</div>
        </Alert>
      );
  }
}
