// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import './styles.css';

export const Bordered: React.FC<{ className?: string }> = ({ children, className }) => (
  <div
    className={className}
    style={{
      border: 'solid',
      borderWidth: 1,
      borderRadius: 3,
      borderColor: '#888',
    }}
  >
    {React.Children.map(children, e => React.cloneElement(e, { parent: { bordered: true } }))}
  </div>
);
export const Text: React.FC<{ parent?: { bordered: boolean }; className?: string }> = ({ children, className }) => (
  <div
    className={className}
    style={
      {
        // margin: parent?.bordered ? 10 : 0,
      }
    }
  >
    {children}
  </div>
);

export const LabPage = () => (
  <div>
    <Bordered className={'bordered'}>
      <Text className={'text'}>Title text</Text>
    </Bordered>
    <Text className={'text'}>Title text</Text>
    <Text className={'text'}>Title text</Text>
  </div>
);
