import { Grid } from '@material-ui/core';
import { RelatedArticleTags, TripId } from '@tripr/common';
import React from 'react';
import { Field } from 'react-final-form';
import { FormList } from '../../common/FormList';
import { MySelect } from '../../common/forms/MySelect';

export const RelatedArticlesList: React.FC<{ name: string; articleTags: { [key: string]: string } }> = ({ name, articleTags }) => (
  <FormList<RelatedArticleTags>
    name={name}
    newValue={() => ({ id: '' })}
    render={p => (
      <Grid container spacing={1}>
        <Field component={MySelect} name={`${p.parentName}.id`} label="Tag" xs={12} options={articleTags} />
      </Grid>
    )}
  />
);
