import { createStyles, Drawer, List, ListItemIcon, ListItemText, MenuItem, SvgIconProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Account, hasAdminAccess } from '@tripr/common';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LayersIcon from '@material-ui/icons/Layers';
import PeopleIcon from '@material-ui/icons/People';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PaymentsIcon from '@material-ui/icons/Payment';
import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { AuthContext, authManager } from '../api/Firebase';
import logo from '../images/logoBlack.png';
import { useAuthContext } from '../utils/AuthContext';
import ErrorBoundary from './common/ErrorBoundary';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    menuLink: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
  }),
);

const Routes = [
  // {
  //   path: '/dashboard',
  //   sidebarName: 'Dashboard',
  //   navbarName: 'Dashboard',
  //   icon: DashboardIcon,
  // },
  {
    path: '/trips',
    sidebarName: 'Trips',
    icon: LayersIcon,
  },
  {
    path: '/hosts',
    sidebarName: 'Hosts',
    icon: PeopleIcon,
  },
  {
    path: '/supplier',
    sidebarName: 'Profile',
    icon: LayersIcon,
  },
];

const AdminRoutes = [
  ...Routes,
  {
    path: '/reservations',
    sidebarName: 'Reservations',
    icon: ShoppingCartIcon,
  },
  {
    path: '/coupons',
    sidebarName: 'Coupons',
    icon: LocalOfferIcon,
  },

  {
    path: '/emailreg',
    sidebarName: 'Email Registrations',
    icon: LayersIcon,
  },
];

const SuperAdminRoutes = [
  ...AdminRoutes,
  {
    path: '/installments',
    sidebarName: 'Installments',
    icon: PaymentsIcon,
  },
];

const MenuItemComp: React.FC<{ selected: boolean; name: string; onClick?(): void; Icon(props: SvgIconProps): JSX.Element }> = ({
  selected,
  name,
  onClick,
  Icon,
}) => (
  <MenuItem onClick={onClick} selected={selected}>
    <ListItemIcon>
      <Icon />
    </ListItemIcon>
    <ListItemText primary={name} />
  </MenuItem>
);

const getRoutes = (accountType: Account['accountType']) => {
  if (accountType === 'admin') return AdminRoutes;
  else if (accountType === 'superadmin') return SuperAdminRoutes;
  return Routes;
};

export const TripSidebar: React.FC<{ authContext: AuthContext }> = ({ authContext }) => {
  const location = useLocation();
  const classes = useStyles();

  if (!authContext.isLoggedIn) return null;

  const routes = getRoutes(authContext.account.accountType);

  return (
    <Drawer
      variant="permanent"
      open={true}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <List>
        <Link to="/">
          <img src={logo} alt="" />
        </Link>

        {routes.map((item, key) => (
          <NavLink key={key} to={item.path} className={classes.menuLink}>
            <MenuItemComp name={item.sidebarName} selected={item.path === location.pathname} Icon={item.icon} />
          </NavLink>
        ))}
        <MenuItemComp name={'Sign Out'} onClick={authManager.logOut} selected={false} Icon={PeopleIcon} />
      </List>
    </Drawer>
  );
};

export const BasePage: React.FC = props => {
  const classes = useStyles();
  const authContext = useAuthContext();
  if (!authContext.isLoggedIn) {
    return <ErrorBoundary>{props.children}</ErrorBoundary>;
  }
  return (
    <div className={classes.root}>
      <TripSidebar authContext={authContext} />
      <main className={classes.content}>
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </main>
    </div>
  );
};
