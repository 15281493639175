import { Typography } from '@material-ui/core';
import { HostId, HostProfile } from '@tripr/common';
import React from 'react';
import { useParams } from 'react-router-dom';
import { HostApi } from '../../api/RpcClient';
import { useToast } from '../../utils/Utils';
import { LoadingGuard } from '../common/LoadingGuard';
import { HostProfileForm, HostProfileValues } from './HostProfileForm';

export const HostProfileEditPage: React.FC = () => {
  const params = useParams<{ hostId: HostId }>();
  const [callWithToast] = useToast('host profile');

  return (
    <LoadingGuard loadData={() => HostApi.getHost(params.hostId as HostId)}>
      {host => (
        <div>
          <Typography gutterBottom variant="h2">
            {host.firstName} {host.lastName}
          </Typography>

          <HostProfileForm
            host={host}
            onSubmit={async (values: HostProfileValues) => {
              const newHost: HostProfile = {
                hostId: host.hostId,
                supplierProfileId: host.supplierProfileId,
                ...values,
              };

              await callWithToast(HostApi.updateHost(newHost));
            }}
          />
        </div>
      )}
    </LoadingGuard>
  );
};
