import { Grid } from '@material-ui/core';
import { Accommodation, AccommodationType, PetAllowance, UploadedImage } from '@tripr/common';
import React from 'react';
import { Field } from 'react-final-form';
import { ImagesApi } from '../../../api/ImagesApi';
import { FormList } from '../../common/FormList';
import { MySelect } from '../../common/forms/MySelect';
import { MyTextField } from '../../common/forms/TextField';
import { MultiImageUploader } from '../../common/MultiImageUploader';

const uploadAccommodationImg = async (file: File): Promise<UploadedImage> => new ImagesApi().uploadImage('accommodation', file);

export const AccommodationList: React.FC<{ name: string }> = ({ name }) => (
  <FormList<Accommodation>
    className="accommodation"
    name={name}
    newValue={() => ({ dayStart: 0, numberOfDays: 0, accommodationType: undefined, petAllowance: undefined, description: '', photos: [] })}
    render={p => (
      <Grid container spacing={2}>
        <Field component={MyTextField} name={`${p.parentName}.dayStart`} label="Day Start" xs={4} variant={'filled'} type={'number'} />
        <Field component={MyTextField} name={`${p.parentName}.numberOfDays`} label="Days" xs={4} variant={'filled'} type={'number'} />
        <Field component={MySelect} name={`${p.parentName}.accommodationType`} label="Accommodation Type" xs={4} options={AccommodationType} />
        <Field component={MyTextField} name={`${p.parentName}.description`} label="Description" xs={12} variant={'filled'} multiline />
        <Field component={MySelect} name={`${p.parentName}.petAllowance`} label="Pet allowance" xs={4} options={PetAllowance} />
        <MultiImageUploader name={`${p.parentName}.photos`} uploader={uploadAccommodationImg} size={[240, 240]} />
      </Grid>
    )}
  />
);
