import { Typography } from '@material-ui/core';
import { SupplierProfile } from '@tripr/common';
import React from 'react';
import { SupplierApi } from '../../api/RpcClient';
import { useAuthContext } from '../../utils/AuthContext';
import { useToast } from '../../utils/Utils';
import { LoadingGuard } from '../common/LoadingGuard';
import { SupplierProfileForm, SupplierProfileValues } from './SupplierProfileForm';

export const SuppliersProfileEditPage: React.FC = () => {
  const [callWithToast] = useToast('profile');
  const authContext = useAuthContext();
  const supplierProfileId = authContext.isLoggedIn ? authContext.account.supplierProfileId : undefined;
  if (!supplierProfileId) {
    return null;
  }
  return (
    <LoadingGuard loadData={() => SupplierApi.getSupplier(supplierProfileId)}>
      {supplier => (
        <div>
          <Typography gutterBottom variant="h2">
            Supplier profile
          </Typography>
          <SupplierProfileForm
            supplier={supplier}
            onSubmit={async (values: SupplierProfileValues) => {
              const newSupplier: SupplierProfile = {
                supplierId: supplier.supplierId,
                ...values,
              };

              await callWithToast(SupplierApi.updateSupplier(newSupplier));
            }}
          />
        </div>
      )}
    </LoadingGuard>
  );
};
