import { Box, Button, Grid, LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { FormSpy } from 'react-final-form';

export const SubmitButton: React.FC<{ caption?: string; handleSubmit(): void }> = ({ caption, handleSubmit }) => (
  <Grid item xs={12}>
    <FormSpy subscription={{ invalid: true, submitting: true, submitFailed: true }}>
      {props => (
        <div>
          <Box display={'flex'} alignItems={'center'}>
            <Button size={'large'} variant="contained" color="primary" disabled={props.submitting} onClick={handleSubmit}>
              {caption || 'SAVE'}
            </Button>

            <Box flex={1} marginLeft={2}>
              {props.submitting && <LinearProgress />}

              {props.invalid && props.submitFailed && (
                <Alert
                  severity="error"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        const el = document.querySelector('.Mui-error');
                        if (el) {
                          el.scrollIntoView({ behavior: 'smooth' });
                        }
                      }}
                    >
                      GO TO ERROR
                    </Button>
                  }
                >
                  Please fix errors in the form
                </Alert>
              )}
            </Box>
          </Box>
        </div>
      )}
    </FormSpy>
  </Grid>
);
