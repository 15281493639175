import { Box, Button, Typography } from '@material-ui/core';
import { Coupon, CouponId } from '@tripr/common';
import { format, parseISO } from 'date-fns';
import MaterialTable from 'material-table';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CouponApi } from '../../api/RpcClient';
import { LoadingGuard } from '../common/LoadingGuard';

const RenderLimits = (coupon: Coupon) => {
  const limits = [];
  if (coupon.reservationTypes.regular || !coupon.reservationTypes.itineraryOnly) {
    limits.push(`trips only`);
  }
  if (!coupon.reservationTypes.regular || coupon.reservationTypes.itineraryOnly) {
    limits.push(`itinerary only`);
  }
  if (coupon.totalUsageLimit) {
    limits.push(`total ${coupon.totalUsageLimit} usages`);
  }
  if (coupon.perCustomerLimit) {
    limits.push(`${coupon.perCustomerLimit} per customer`);
  }
  if (coupon.minPurchaseAmount) {
    limits.push(`$${coupon.minPurchaseAmount} min purchase`);
  }
  if (coupon.onlyForEmails && coupon.onlyForEmails.length > 0) {
    limits.push(`for specific emails`);
  }
  if (coupon.onlyForTrips && coupon.onlyForTrips.length > 0) {
    limits.push(`for specific trips`);
  }
  if (coupon.validAfter) {
    limits.push(`after ${coupon.validAfter}`);
  }
  if (coupon.validBefore) {
    limits.push(`before ${coupon.validBefore}`);
  }
  return limits.join(', ');
};

export const CouponListPage: React.FC = () => {
  const history = useHistory();

  const onEdit = (couponId: CouponId) => {
    history.push(`/coupons/edit/${couponId}`);
  };

  return (
    <LoadingGuard loadData={() => CouponApi.getCouponList()}>
      {coupons => (
        <div>
          <Typography gutterBottom variant="h2">
            Coupons
          </Typography>
          <Box mb={2}>
            <Button variant="contained" color="primary" onClick={() => history.push('/coupons/add')}>
              Add coupon
            </Button>
          </Box>
          <MaterialTable
            columns={[
              { title: 'Coupon code', field: 'couponId' },
              { title: 'Title', field: 'title' },
              {
                title: 'Discount',
                render: row => (row.discountSpec.discountType === 'amount' ? `$${row.discountSpec.amountOff}` : `${row.discountSpec.percentOff}%`),
              },
              { title: 'Limits', render: RenderLimits },
              { title: 'Created At', type: 'datetime', field: 'createdAt', render: d => (d.createdAt ? format(parseISO(d.createdAt), 'd MMM yy, HH:mm') : '') },
            ]}
            onRowClick={(_, rowData) => {
              if (rowData) {
                onEdit(rowData.couponId);
              }
            }}
            data={coupons}
            title=""
            options={{
              paging: false,
              actionsColumnIndex: -1,
            }}
          />
        </div>
      )}
    </LoadingGuard>
  );
};
