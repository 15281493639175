import { Box, Grid, Typography } from '@material-ui/core';
import { IsoCountryCode, isoCountryCodeList, isoCountryMap, UploadedImage } from '@tripr/common';
import React from 'react';
import { Field, Form } from 'react-final-form';
import * as yup from 'yup';
import { StringSchema } from 'yup';
import { OptionalImage } from '../../api/CommonSchemas';
import { ImagesApi } from '../../api/ImagesApi';
import { MySelect } from '../common/forms/MySelect';
import { MyTextField } from '../common/forms/TextField';
import { yupValidate } from '../common/forms/YupValidate';
import { MyImageInput } from '../common/SingleImageUploader';
import { SubmitButton } from '../common/SubmitButton';

const SupplierProfileSchema = yup.object({
  companyName: yup.string().required(),
  description: yup.string().required(),
  logo: OptionalImage,
  website: yup.string(),
  facebook: yup.string(),
  instagram: yup.string(),
  contactEmail: yup.string().required(),
  contactPhoneNumber: yup.string().required(),

  address: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zipCode: yup.string().required(),
  country: yup.string().oneOf(isoCountryCodeList, 'Country required') as StringSchema<IsoCountryCode>,

  bankName: yup.string(),
  bankCode: yup.string(),
  bankBranchName: yup.string(),
  bankBranchCode: yup.string(),
  bankAccountName: yup.string(),
  bankAccountCode: yup.string(),
  bankCountry: yup.string().oneOf(isoCountryCodeList, 'Country required') as StringSchema<IsoCountryCode>,
});

export type SupplierProfileValues = yup.InferType<typeof SupplierProfileSchema>;
const validator = yupValidate(SupplierProfileSchema);
const InitialValues: SupplierProfileValues = SupplierProfileSchema.cast();

const uploadLogoImg = async (profilePic: File): Promise<UploadedImage> => new ImagesApi().uploadImage('logo', profilePic);

export const SupplierProfileForm: React.FC<{ supplier?: SupplierProfileValues; onSubmit(values: SupplierProfileValues): Promise<void> }> = ({
  supplier,
  onSubmit,
}) => (
  <div>
    <Form<SupplierProfileValues>
      initialValues={supplier || InitialValues}
      validate={validator}
      subscription={{}}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box maxWidth={640}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MyImageInput name={'logo'} uploader={uploadLogoImg} size={[120, 120]} />
              </Grid>
              <Field component={MyTextField} name="companyName" label="Company Name" xs={6} />
              <Field component={MyTextField} name="description" label="Description" xs={12} multiline />

              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Typography variant={'h4'}>Contacts</Typography>
              </Grid>
              <Field component={MyTextField} name="website" label="Website" xs={6} />
              <Field component={MyTextField} name="facebook" label="Facebook" xs={6} />
              <Field component={MyTextField} name="instagram" label="Instagram" xs={6} />
              <Grid item xs={12} />
              <Field component={MyTextField} name="contactEmail" label="Email" xs={6} />
              <Field component={MyTextField} name="contactPhoneNumber" label="Phone Number" xs={6} />

              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Typography variant={'h4'}>Address</Typography>
              </Grid>
              <Field component={MyTextField} name="address" label="Address" xs={12} />
              <Field component={MyTextField} name="city" label="City" xs={5} />
              <Field component={MyTextField} name="state" label="State" xs={4} />
              <Field component={MyTextField} name="zipCode" label="ZipCode" xs={3} />
              <Field component={MySelect} name="country" label="Country" xs={5} options={isoCountryMap} />

              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Typography variant={'h4'}>Bank account</Typography>
              </Grid>
              <Field component={MyTextField} name="bankName" label="Bank Name" xs={12} />
              <Field component={MyTextField} name="bankCode" label="Bank Code" xs={12} />
              <Field component={MyTextField} name="bankBranchName" label="Branch Name" xs={12} />
              <Field component={MyTextField} name="bankBranchCode" label="Branch Code" xs={12} />
              <Field component={MyTextField} name="bankAccountName" label="Account Name" xs={12} />
              <Field component={MyTextField} name="bankAccountCode" label="Account Code" xs={12} />
              <Field component={MySelect} name="bankCountry" label="Bank Country" xs={12} options={isoCountryMap} />

              <SubmitButton caption="Save profile" handleSubmit={handleSubmit} />
            </Grid>
          </Box>
        </form>
      )}
    />
  </div>
);
