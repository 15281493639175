// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const envName: 'dev' | 'int' | 'prod' | '__ENVIRONMENT_NAME__' = window.ENVIRONMENT_NAME;

const envVariables = {
  // When template var is not overwritten
  __ENVIRONMENT_NAME__: {
    REACT_APP_HOST: '127.0.0.1:3001',
    REACT_APP_CONSUMERS_URL: 'http://127.0.0.1:3000',
    REACT_APP_API_URL: 'http://127.0.0.1:3333/api/v1',
    REACT_APP_CDN_URL: 'https://cdn-dev.adventuretripr.com',
    REACT_APP_FIREBASE_APP_ID: 'b6fc35ffda8dca8213a525',
  },
  dev: {
    REACT_APP_HOST: 'host-dev.adventuretripr.com',
    REACT_APP_CONSUMERS_URL: 'https://dev.adventuretripr.com',
    REACT_APP_API_URL: 'https://api-dev.adventuretripr.com/api/v1',
    REACT_APP_CDN_URL: 'https://cdn-dev.adventuretripr.com',
    REACT_APP_FIREBASE_APP_ID: 'b6fc35ffda8dca8213a525',
  },
  int: {
    REACT_APP_HOST: 'host-int.adventuretripr.com',
    REACT_APP_CONSUMERS_URL: 'https://int.adventuretripr.com',
    REACT_APP_API_URL: 'https://api-int.adventuretripr.com/api/v1',
    REACT_APP_CDN_URL: 'https://cdn-int.adventuretripr.com',
    REACT_APP_FIREBASE_APP_ID: 'c913ab9eff7c6f9713a525',
  },
  prod: {
    REACT_APP_HOST: 'host.adventuretripr.com',
    REACT_APP_CONSUMERS_URL: 'https://adventuretripr.com',
    REACT_APP_API_URL: 'https://api.adventuretripr.com/api/v1',
    REACT_APP_CDN_URL: 'https://cdn.adventuretripr.com',
    REACT_APP_FIREBASE_APP_ID: 'b599e3ba68d97ce813a525',
  },
}[envName];

export const Configuration = {
  DomainName: envVariables.REACT_APP_HOST ?? '',
  ConsumersUrl: envVariables.REACT_APP_CONSUMERS_URL,
  SuppliersUrl: `https://${envVariables.REACT_APP_HOST}`,

  ApiUrl: envVariables.REACT_APP_API_URL,
  ImagesCdnUrl: envVariables.REACT_APP_CDN_URL,

  tosUrl: `${envVariables.REACT_APP_CONSUMERS_URL}/terms_of_use`,
  privacyPolicyUrl: `${envVariables.REACT_APP_CONSUMERS_URL}/privacy_policy`,

  FirebaseCredentials: {
    apiKey: 'AIzaSyC45GQrFJrM53FZw_OL9YQDCjwD3jz8PDE',
    authDomain: 'banded-torus-263617.firebaseapp.com',
    databaseURL: 'https://banded-torus-263617.firebaseio.com',
    projectId: 'banded-torus-263617',
    storageBucket: 'banded-torus-263617.appspot.com',
    messagingSenderId: '1085983975616',
    appId: '1:1085983975616:web:' + envVariables.REACT_APP_FIREBASE_APP_ID,
  },
};
