import { PaymentWithReservation } from '@tripr/common';
import { makeApiCall } from './ImagesApi';

export class PaymentApi {
  public static getPayments = async () => {
    try {
      const response = await makeApiCall<{ statusCode: number; result: PaymentWithReservation[] }>('GET', 'Payments');
      if (response.result) {
        return response.result;
      } else {
        return Promise.reject('No result found in response');
      }
    } catch (error) {
      return Promise.reject(`Error fetching payments: ${error}`);
    }
  };

  public static chargePayments = async (ids: Array<string>) => {
    try {
      const response = await makeApiCall<{ statusCode: number; result: any }>('POST', 'Payments/ChargePayments', JSON.stringify({ ids }));
      if (response.statusCode == 200) {
        return response.result;
      }
      return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  public static cancelPayments = async (ids: Array<string>) => {
    try {
      const response = await makeApiCall<{ statusCode: number; result: any }>('POST', 'Payments/CancelPayments', JSON.stringify({ ids }));
      if (response.statusCode == 200) {
        return response.result;
      }
      return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
}
