import { Image } from '@tripr/common';
import * as yup from 'yup';
import { ArraySchema, ObjectSchema } from 'yup';

export const ImageShape = {
  hash: yup.string().required(),
  lqip: yup.string().required(),
  description: yup.string().notRequired(),
};

export const RequiredImage: ObjectSchema<Image> = yup.object<Image>().required().shape(ImageShape);

export const OptionalImage: ObjectSchema<Image | null> = yup.object<Image>().default(null).nullable().shape(ImageShape);

export const ImageArray: ArraySchema<Image> = yup.array<Image>().default([]).of(yup.object().shape(ImageShape));
