import { Avatar, Box, Button, Card, CardActionArea, CardActions, CardContent, Grid, IconButton, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { HostProfile } from '@tripr/common';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { GetImageUrl } from '../../api/ImagesApi';
import { HostApi } from '../../api/RpcClient';
import { LoadingGuard } from '../common/LoadingGuard';
import useConfirmationDialog from '../common/UseConfirmationDialog';
import { useMenu } from '../common/WithMenu';

export const HostItem: React.FC<{ host: HostProfile }> = ({ host }) => {
  const history = useHistory();
  const [showDialog, renderDialog] = useConfirmationDialog({ title: 'Confirmation', content: 'Are you sure that you want to delete the host' });

  const onEdit = () => {
    history.push(`/hosts/${host.hostId}`);
  };

  const onDelete = () => {
    showDialog();
  };

  const [showMenu, renderMenu] = useMenu({
    options: [
      { label: 'Edit', onClick: onEdit },
      { label: 'Delete', onClick: onDelete },
    ],
  });

  return (
    <Card>
      <CardActionArea onClick={onEdit}>
        <Box display="flex" flexDirection="column" alignItems="center" my={2}>
          <Avatar src={GetImageUrl(host.profilePic, 120, 120)} style={{ width: 120, height: 120 }} />
        </Box>

        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" align="center" noWrap>
            {host.firstName} {host.lastName}
          </Typography>

          <Box height={40} overflow="hidden">
            <Typography variant="body2" color="textSecondary" component="p">
              {host.bio}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" onClick={onEdit}>
          Edit profile
        </Button>
        <IconButton style={{ marginLeft: 'auto' }} onClick={showMenu}>
          <MoreVertIcon />
        </IconButton>
        {renderMenu()}
        {renderDialog()}
      </CardActions>
    </Card>
  );
};

export const HostProfileListPage: React.FC = () => {
  const history = useHistory();

  return (
    <LoadingGuard loadData={() => HostApi.getHosts()}>
      {hosts => (
        <div>
          <Typography gutterBottom variant="h2">
            Hosts
          </Typography>
          <Box mb={2}>
            <Button variant="contained" color="primary" onClick={() => history.push('/hosts/add')}>
              Add host
            </Button>
          </Box>
          <Grid container spacing={3}>
            {hosts.map(host => (
              <Grid key={host.hostId} item style={{ width: 320 }}>
                <HostItem key={host.hostId} host={host} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </LoadingGuard>
  );
};
