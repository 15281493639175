import { Typography } from '@material-ui/core';
import { Review, TripId } from '@tripr/common';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReviewApi, TripWithVersionApi } from '../../api/RpcClient';
import { useToast } from '../../utils/Utils';
import { LoadingGuard } from '../common/LoadingGuard';
import { ReviewForm, ReviewValues } from './ReviewForm';

export const ReviewAddPage: React.FC<{ tripId: TripId }> = props => {
  const history = useHistory();
  const [callWithToast] = useToast('review');

  return (
    <LoadingGuard
      loadData={async () => {
        const trip = await TripWithVersionApi.getWithVersion(props.tripId);
        return { trip };
      }}
    >
      {({ trip }) => (
        <div>
          <Typography gutterBottom variant="h6">
            New review for "{trip.currentVersion.title}" trip
          </Typography>
          <ReviewForm
            onSubmit={async (values: ReviewValues) => {
              const newReview: Omit<Review, 'reviewId'> = { ...values, tripId: props.tripId };
              await callWithToast(ReviewApi.createReview(newReview));
              history.push(`/trips/${props.tripId}/reviews`);
            }}
          />
        </div>
      )}
    </LoadingGuard>
  );
};
