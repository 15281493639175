import React from 'react';
import { RouteComponentProps, RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { useAuthContext } from './utils/AuthContext';

export const App: React.FC = () => <Redirect to={'/trips'} />;

export const PrivateRoute: React.FC<{ component: React.ComponentType<RouteComponentProps> | React.ComponentType } & RouteProps> = ({ component, ...rest }) => {
  const { isLoggedIn } = useAuthContext();

  return isLoggedIn ? <Route {...rest} component={component} /> : <Route {...rest} render={() => <Redirect to={'/signin'} />} />;
};
