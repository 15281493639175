import { format, parseISO } from 'date-fns';
import MaterialTable from 'material-table';
import React from 'react';
import { EmailRegApi } from '../../api/RpcClient';
import { LoadingGuard } from '../common/LoadingGuard';

export const EmailRegListPage: React.FC = () => (
  <LoadingGuard loadData={() => EmailRegApi.getEmailRegs()}>
    {emailRegs => (
      <div>
        <MaterialTable
          columns={[
            { title: 'Name', field: 'name' },
            { title: 'Email', field: 'email' },
            { title: 'Added', field: 'created', render: d => format(parseISO(d.created), 'do MMMM yyyy HH:mm') },
          ]}
          data={emailRegs}
          title="Email Registrations"
          options={{
            paging: false,
            search: false,
            actionsColumnIndex: -1,
          }}
        />
      </div>
    )}
  </LoadingGuard>
);
