import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';

export default class ErrorBoundary extends React.Component {
  public state = { hasError: false };

  public componentDidCatch(): void {
    this.setState({
      hasError: true,
    });
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Something went wrong :(
        </Alert>
      );
    }

    return this.props.children;
  }
}
