import { Account, createEvents } from '@tripr/common';
import firebase from 'firebase/app';
import 'firebase/auth';
import * as firebaseui from 'firebaseui';
import { Configuration } from './Config';
import { AuthApi } from './ImagesApi';

firebase.initializeApp(Configuration.FirebaseCredentials);

export type AuthContext =
  | { isLoading: true; isLoggedIn: false }
  | { isLoading: false; isLoggedIn: false }
  | { isLoading: false; isLoggedIn: true; account: Account };

class AuthManager {
  private readonly authEvents = createEvents<{ authChanged(authContext: AuthContext): void }>();
  private user: firebase.User | null = null;
  private authContext: AuthContext = { isLoading: true, isLoggedIn: false };

  public constructor() {
    firebase.auth().onAuthStateChanged(this.updateContext);
  }

  public onAuthChanged = (cb: (authContext: AuthContext) => void) => {
    const subscription = this.authEvents.on('authChanged', cb);
    this.authEvents.emit('authChanged', this.authContext);
    return subscription;
  };

  public getToken = async () => {
    if (this.user) {
      return this.user.getIdToken();
    }
    return null;
  };

  public getFirebaseUiProps = () => ({
    uiConfig: {
      callbacks: {
        // Do not redirect Sign in page will redirect itself
        signInSuccessWithAuthResult: () => false,
      },
      signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      tosUrl: Configuration.tosUrl,
      privacyPolicyUrl: Configuration.privacyPolicyUrl,
    } as firebaseui.auth.Config,
    firebaseAuth: firebase.auth(),
  });

  public logOut = () => firebase.auth().signOut();

  private readonly updateContext = async (user: firebase.User | null) => {
    this.user = user;
    this.authContext = { isLoading: false, isLoggedIn: false };

    if (user) {
      const response = await new AuthApi().getOrCreate();

      if (response.status === 'success' && response.result.entities.length === 1) {
        this.authContext = {
          isLoading: false,
          isLoggedIn: true,
          account: response.result.entities[0],
        };
      }
    }

    this.authEvents.emit('authChanged', this.authContext);
  };
}

export const authManager = new AuthManager();
