import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

export interface MenuOption {
  label: string;
  onClick(): void;
}

type Result = [(event: React.MouseEvent<HTMLButtonElement>) => void, () => React.ReactNode];

export const useMenu: (props: { options: MenuOption[] }) => Result = (props: { options: MenuOption[] }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = () => (
    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
      {props.options.map(option => (
        <MenuItem key={option.label} onClick={option.onClick}>
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  );

  return [showMenu, renderMenu];
};
