import { FormControl, FormHelperText, Grid, GridSize } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format, parse } from 'date-fns';
import * as React from 'react';
import { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';

export const DateStringField: React.FC<{ value: string; name?: string; label?: string; onChange(value?: string): void }> = ({
  label,
  name,
  value,
  onChange,
}) => {
  const [tmpValue, setTmpValue] = useState<Date | null>(value ? parse(value, 'yyyy-MM-dd', 0) : null);

  const onTmpChange = (date: MaterialUiPickersDate | null) => {
    setTmpValue(date);
    onChange(date instanceof Date && !isNaN(date.getTime()) ? format(date, 'yyyy-MM-dd') : undefined);
  };

  return (
    <KeyboardDatePicker
      disableToolbar
      autoOk
      variant="inline"
      format="MM/dd/yyyy"
      margin="none"
      name={name}
      label={label}
      value={tmpValue}
      placeholder={'mm/dd/yyyy'}
      onChange={onTmpChange}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      style={{
        minWidth: 200,
      }}
    />
  );
};

export const DateStringTableField = (props: { value: string; onChange(value?: string): void }) => (
  <DateStringField value={props.value} onChange={props.onChange} />
);

export const MyDateField: React.FC<FieldRenderProps<string> & { xs: GridSize }> = ({ label, input: { name, onChange, value }, meta, formControlProps, xs }) => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
  return (
    <Grid item xs={xs}>
      <FormControl {...formControlProps} error={showError} fullWidth>
        <DateStringField label={label} name={name} value={value} onChange={onChange} />
        {showError && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};
