import { Box, Grid, Typography } from '@material-ui/core';
import { UploadedImage } from '@tripr/common';
import React from 'react';
import { Field, Form } from 'react-final-form';
import * as yup from 'yup';
import { ImageArray, RequiredImage } from '../../api/CommonSchemas';
import { ImagesApi } from '../../api/ImagesApi';
import { MyTextField } from '../common/forms/TextField';
import { yupValidate } from '../common/forms/YupValidate';
import { MultiImageUploader } from '../common/MultiImageUploader';
import { MyImageInput } from '../common/SingleImageUploader';
import { SubmitButton } from '../common/SubmitButton';

const HostProfileSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  bio: yup.string().default(''),
  facebook: yup.string().default('').url(),
  instagram: yup.string().default('').url(),
  profilePic: RequiredImage,
  portfolio: ImageArray,
});

export type HostProfileValues = yup.InferType<typeof HostProfileSchema>;
const validator = yupValidate(HostProfileSchema);
const InitialValues: HostProfileValues = HostProfileSchema.cast();

const uploadProfileImg = async (profilePic: File): Promise<UploadedImage> => new ImagesApi().uploadImage('profile', profilePic);
const uploadPortfolioImg = async (profilePic: File): Promise<UploadedImage> => new ImagesApi().uploadImage('portfolio', profilePic);

export const HostProfileForm: React.FC<{ host?: HostProfileValues; onSubmit(values: HostProfileValues): Promise<void> }> = ({ host, onSubmit }) => (
  <div>
    <Form<HostProfileValues>
      initialValues={host || InitialValues}
      validate={validator}
      subscription={{}}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box maxWidth={640}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MyImageInput name={'profilePic'} uploader={uploadProfileImg} size={[120, 120]} />
              </Grid>
              <Field component={MyTextField} name="firstName" label="First Name" xs={6} />
              <Field component={MyTextField} name="lastName" label="Last Name" xs={6} />
              <Field component={MyTextField} name="bio" label="Bio" multiline xs={12} />
              <Field component={MyTextField} name="facebook" label="Facebook" xs={6} />
              <Field component={MyTextField} name="instagram" label="Instagram" xs={6} />

              <Grid item xs={12}>
                <Typography variant={'h4'}>Portfolio</Typography>
                <MultiImageUploader name={'portfolio'} uploader={uploadPortfolioImg} size={[480, 280]} />
              </Grid>

              <SubmitButton caption="Save host profile" handleSubmit={handleSubmit} />
            </Grid>
          </Box>
        </form>
      )}
    />
  </div>
);
