import { FormControl, FormHelperText, Grid, GridSize } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';

export const NumberField = ({ value, onChange, placeholder }: { value?: number | null; placeholder?: string; onChange(value?: number): void }) => (
  <TextField
    onChange={v => onChange(parseInt(v.target.value, 10))}
    value={value !== undefined && value !== null ? value.toString(10) : ''}
    type={'number'}
    placeholder={placeholder}
  />
);

export const BooleanField: React.FC<FieldRenderProps<boolean> & { xs: GridSize }> = ({
  input: { name, onChange, value },
  meta,
  formControlProps,
  xs,
  label,
}) => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <Grid item xs={xs}>
      <FormControl {...formControlProps} error={showError} fullWidth>
        <div>
          {label}
          <Switch name={name} onChange={onChange} checked={value} />
        </div>
        {showError && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

export const MyTextField: React.FC<FieldRenderProps<string> & { xs: GridSize } & { capitalize?: boolean }> = ({
  input: { name, onChange, value, ...restInput },
  meta,
  xs,
  capitalize,
  ...rest
}) => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const transformedValue = capitalize ? event.target.value.toUpperCase() : event.target.value;
    onChange(transformedValue);
  };

  return (
    <Grid item xs={xs}>
      <TextField
        fullWidth
        {...rest}
        name={name}
        helperText={showError ? meta.error || meta.submitError : undefined}
        error={showError}
        inputProps={restInput}
        onChange={handleChange}
        value={value}
      />
    </Grid>
  );
};
