import { Typography } from '@material-ui/core';
import { HostProfile } from '@tripr/common';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { HostApi } from '../../api/RpcClient';
import { useAuthContext } from '../../utils/AuthContext';
import { useToast } from '../../utils/Utils';
import { LoadingGuard } from '../common/LoadingGuard';
import { HostProfileForm, HostProfileValues } from './HostProfileForm';

export const HostProfileAddPage: React.FC = () => {
  const history = useHistory();
  const [callWithToast] = useToast('host profile');
  const authContext = useAuthContext();
  const supplierProfileId = authContext.isLoggedIn ? authContext.account.supplierProfileId : undefined;
  if (!supplierProfileId) {
    return null;
  }
  return (
    <LoadingGuard loadData={() => HostApi.getHosts()}>
      {() => (
        <div>
          <Typography gutterBottom variant="h2">
            Add host
          </Typography>
          <HostProfileForm
            onSubmit={async (values: HostProfileValues) => {
              const newHost: Omit<HostProfile, 'hostId'> = {
                ...values,
                supplierProfileId,
              };

              const hostId = await callWithToast(HostApi.createHost(newHost));
              history.push(`/hosts/${hostId.toString()}`);
            }}
          />
        </div>
      )}
    </LoadingGuard>
  );
};
