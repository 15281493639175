import { Typography } from '@material-ui/core';
import { Review, ReviewId, TripId } from '@tripr/common';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReviewApi, TripWithVersionApi } from '../../api/RpcClient';
import { useToast } from '../../utils/Utils';
import { LoadingGuard } from '../common/LoadingGuard';
import { ReviewForm, ReviewValues } from './ReviewForm';

export const ReviewEditPage: React.FC<{ tripId: TripId; reviewId: ReviewId }> = props => {
  const history = useHistory();
  const [callWithToast] = useToast('review');

  return (
    <LoadingGuard
      loadData={async () => {
        const trip = await TripWithVersionApi.getWithVersion(props.tripId);
        const review = await ReviewApi.getReview(props.reviewId);

        return { trip, review };
      }}
    >
      {({ trip, review }) => (
        <div>
          <Typography gutterBottom variant="h6">
            Edit review for "{trip.currentVersion.title}" trip
          </Typography>
          <ReviewForm
            review={review}
            onSubmit={async (values: ReviewValues) => {
              const updatedReview: Review = { ...values, tripId: props.tripId, reviewId: props.reviewId };
              await callWithToast(ReviewApi.updateReview(updatedReview));
              history.push(`/trips/${props.tripId}/reviews`);
            }}
          />
        </div>
      )}
    </LoadingGuard>
  );
};
