import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, IconButton, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch';
import OpenInNew from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TripId, TripStatus, TripWithVersion, isoCountryMap } from '@tripr/common';
import _ from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Configuration } from '../../api/Config';
// import { GetImageUrl } from '../../api/ImagesApi';
import { TripApi, TripWithVersionApi } from '../../api/RpcClient';
import { LoadingGuard } from '../common/LoadingGuard';
import { useMenu } from '../common/WithMenu';
import { downloadBlob } from '../../utils/Utils';
import MaterialTable from 'material-table';
import { useAuthContext } from '../../utils/AuthContext';

export const TripActionItem: React.FC<{
  tripId: TripId;
  isDeleted: boolean;
  isDraft: boolean;
  isActive: boolean;
  isSuperAdmin: boolean;
}> = ({ tripId, isDeleted, isActive, isDraft, isSuperAdmin }) => {
  const history = useHistory();

  const options = [
    { label: 'Edit', onClick: () => window.open(`${Configuration.SuppliersUrl}/trips/${tripId.toString()}/edit`, '_blank') },
    { label: 'Open inventory', onClick: () => history.push(`/trips/${tripId.toString()}/inventory`) },
    {
      label: 'Copy',
      onClick: () => {
        TripWithVersionApi.cloneTripWithVersion(tripId).then(tripId => {
          history.push(`/trips/${tripId.toString()}/edit`);
        });
      },
    },
  ];

  if (isDraft) {
    if (isSuperAdmin) {
      options.push({
        label: 'Activate',
        onClick: () => {
          TripWithVersionApi.toggleTripActiveStatus(tripId).then(() => {
            history.go(0);
          });
        },
      });
    }
  } else {
    options.push({
      label: isActive ? 'Deactivate' : 'Activate',
      onClick: () => {
        TripWithVersionApi.toggleTripActiveStatus(tripId).then(() => {
          history.go(0);
        });
      },
    });
  }

  if (!isDeleted) {
    // show Archive option for trips only that are not archived
    options.push({
      label: 'Archive',
      onClick: () => {
        TripWithVersionApi.archiveTrip(tripId).then(() => {
          history.go(0);
        });
      },
    });
  }

  const [showMenu, renderMenu] = useMenu({
    options,
  });

  return (
    <>
      <IconButton style={{ marginLeft: 'auto' }} onClick={showMenu}>
        <MoreVertIcon />
      </IconButton>
      {renderMenu()}
    </>
  );
};

const downloadCSV = (csv: string) => {
  const blob = new Blob([csv], { type: 'text/csv' });
  downloadBlob(blob, 'trips.csv');
};

export const TripsList: React.FC<{ trips: TripWithVersion[] }> = ({ trips }) => {
  const history = useHistory();
  const authContext = useAuthContext();

  if (!authContext.isLoggedIn) return null;

  const accountType = authContext.account.accountType;

  const [showInactive, setShowInactive] = useState(true);
  const [showDeleted, setShowDeleted] = useState(false);

  let filteredTrips = showInactive ? trips : trips.filter(t => t.status !== TripStatus.Inactive);
  filteredTrips = showDeleted ? filteredTrips : filteredTrips.filter(t => t.status !== TripStatus.Archive);
  const sortedTrips = _.sortBy(filteredTrips, t => t.currentVersion.title);

  const handleDownloadTripsClick = async () => {
    const data = await TripApi.getTripsCSV();
    if (data) {
      downloadCSV(data);
    }
  };

  return (
    <div>
      <Typography gutterBottom variant="h2">
        Trips
      </Typography>
      <Grid container spacing={10}>
        <Grid item xs={4}>
          <Button variant="contained" color="primary" onClick={() => history.push(`/trips/add`)}>
            Add trip
          </Button>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Switch onChange={() => setShowInactive(!showInactive)} checked={showInactive} />
          show inactive
        </Grid>
        <Grid item xs={2}>
          <Switch onChange={() => setShowDeleted(!showDeleted)} checked={showDeleted} />
          show archive
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" color="primary" onClick={handleDownloadTripsClick}>
            Download
          </Button>
        </Grid>
      </Grid>
      <div style={{ marginTop: '2rem' }}>
        <MaterialTable
          title="Trips"
          data={sortedTrips}
          columns={[
            {
              title: 'Title',
              field: 'currentVersion.title',
              render: row => (
                <a style={{ textTransform: 'capitalize' }} href={`${Configuration.SuppliersUrl}/trips/${row.tripId.toString()}/edit`} target="_blank">
                  {row.currentVersion.title}
                </a>
              ),
            },
            {
              title: 'Country',
              field: 'currentVersion.country',
              render: row => (
                <div>
                  <p>{isoCountryMap[row.currentVersion.country]}</p>
                </div>
              ),
            },
            { title: 'Price ($)', field: 'currentVersion.price' },
            { title: 'Duration', field: 'currentVersion.days' },
            {
              title: 'Status',
              field: 'status',
            },
            { title: 'Uploaded By', field: 'uploader.email' },
            { title: 'Operator', field: 'currentVersion.operator' },
            {
              title: 'Actions',
              render: row => (
                <TripActionItem
                  tripId={row.tripId}
                  isDeleted={row.status == TripStatus.Archive}
                  isActive={row.status == TripStatus.Active}
                  isDraft={row.status == TripStatus.Draft}
                  isSuperAdmin={accountType == 'superadmin'}
                />
              ),
            },
          ]}
          options={{
            pageSize: 100,
            actionsColumnIndex: -1,
          }}
        />
      </div>
    </div>
  );
};

export const TripsListPage: React.FC = () => (
  <LoadingGuard loadData={() => TripWithVersionApi.getWithVersionList()}>{trips => <TripsList trips={trips} />}</LoadingGuard>
);
