import { Grid } from '@material-ui/core';
import React from 'react';
import { Field } from 'react-final-form';
import { FormList } from '../../common/FormList';
import { MyTextField } from '../../common/forms/TextField';

export const TagList: React.FC<{ name: string }> = ({ name }) => (
  <FormList<string>
    name={name}
    newValue={() => ''}
    render={p => (
      <Grid container spacing={2}>
        <Field component={MyTextField} name={`${p.parentName}`} label="Tag" xs={12} variant={'filled'} />
      </Grid>
    )}
  />
);
