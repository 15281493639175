import { useCallback } from 'react';
import { TripValues } from '../components/Trips/components/TripForm';

// a simple utility function from stackoverflow to make it bolld
const toBold = (text: string) => {
  const translate = (char: string) => {
    let diff: number;
    if (/[A-Z]/.test(char)) {
      diff = ('𝗔'.codePointAt(0) ?? 0) - ('A'.codePointAt(0) ?? 0);
    } else {
      diff = ('𝗮'.codePointAt(0) ?? 0) - ('a'.codePointAt(0) ?? 0);
    }
    return String.fromCodePoint(char.codePointAt(0)! + diff);
  };

  return text.replace(/[A-Za-z]/g, translate);
};

const useCopyAdminFormToClipboard = (formRef: React.RefObject<HTMLFormElement>) => {
  const fields: { [key: string]: keyof TripValues } = {
    Title: 'title',
    Slug: 'slug',
    'Trip Status': 'status',
    Country: 'country',
    Destination: 'destination',
    Description: 'description',
    Overview: 'overview',
  };

  const copyToClipboard = useCallback(() => {
    if (!formRef.current) return;

    const form = formRef.current;

    const getFieldValue = (name: string) => {
      const field = form.querySelector(`[name="${name}"]`) as HTMLInputElement | null;
      return field ? field.value : '';
    };

    let formattedText = '';
    for (const key in fields) {
      formattedText += `${toBold(key)}:\n`;
      formattedText += `${getFieldValue(fields[key])}\n\n`;
    }

    // itinerary
    const itineraryDays = form.querySelectorAll('.itinerary')[0]?.children;
    if (itineraryDays && itineraryDays?.length) {
      formattedText += `${toBold('Itinerary')}:\n`;
      Array.from(itineraryDays).forEach((day: Element, index: number) => {
        const dayNumber = (day.querySelector(`[name="itinerary[${index}].day"]`) as HTMLInputElement)?.value || (index + 1).toString();
        const headline = (day.querySelector(`[name="itinerary[${index}].headline"]`) as HTMLInputElement)?.value || '';
        const description = (day.querySelector(`[name="itinerary[${index}].description"]`) as HTMLInputElement)?.value || '';
        formattedText += `${toBold('Day')}: ${dayNumber}\n${toBold('Headline')}: ${headline}\n${toBold('Description')}: ${description}\n\n`;
      });
    }

    // Accommodation
    const accds = form.querySelectorAll('.accommodation')[0]?.children;
    if (accds && accds?.length) {
      formattedText += `${toBold('Accommodation')}:\n`;
      Array.from(accds).forEach((day: Element, index: number) => {
        const dayNumber = (day.querySelector(`[name="accommodations[${index}].dayStart"]`) as HTMLInputElement)?.value || (index + 1).toString();
        const days = (day.querySelector(`[name="accommodations[${index}].numberOfDays"]`) as HTMLInputElement)?.value || '';
        const accType = (day.querySelector(`[name="accommodations[${index}].accommodationType"]`) as HTMLInputElement)?.value || '';
        const description = (day.querySelector(`[name="accommodations[${index}].description"]`) as HTMLInputElement)?.value || '';
        formattedText += `${toBold('Day')}: ${dayNumber}\n${toBold('Days')}: ${days}\n${toBold('Accommodation Type')}: ${accType}\n${toBold(
          'Description',
        )}: ${description}\n\n`;
      });
    }

    // FAQ
    const faq = form.querySelectorAll('.faq')[0]?.children;
    if (faq && faq?.length) {
      formattedText += `${toBold('FAQ')}:\n`;
      Array.from(faq).forEach((item: Element, index: number) => {
        const question = (item.querySelector(`[name="faq[${index}].question"]`) as HTMLInputElement)?.value || '';
        const answer = (item.querySelector(`[name="faq[${index}].answer"]`) as HTMLInputElement)?.value || '';
        formattedText += `${toBold('Question')}: ${question}\n${toBold('Answer')}: ${answer}\n\n`;
      });
    }
    formattedText += '\n';

    navigator.clipboard
      .writeText(formattedText)
      .then(() => alert('Form data copied to clipboard!'))
      .catch(err => console.error('Failed to copy: ', err));
  }, [formRef]);

  return copyToClipboard;
};

export default useCopyAdminFormToClipboard;
