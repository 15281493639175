import { IconButton, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { AddBox } from '@material-ui/icons';
import { TripId } from '@tripr/common';
import { format, parse } from 'date-fns';
import MaterialTable from 'material-table';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReviewApi, TripWithVersionApi } from '../../api/RpcClient';
import { DateStringTableField } from '../common/forms/DateField';
import { LoadingGuard } from '../common/LoadingGuard';

export const ReviewListTable: React.FC<{ tripId: TripId }> = props => {
  const history = useHistory();

  return (
    <LoadingGuard
      loadData={async () => {
        const trip = await TripWithVersionApi.getWithVersion(props.tripId);

        const reviewItems = await ReviewApi.getReviewList(props.tripId);
        return { trip, reviewItems };
      }}
    >
      {({ trip, reviewItems }) => (
        <MaterialTable
          columns={[
            {
              title: 'Date',
              field: 'date',
              render: d => format(parse(d.date, 'yyyy-MM-dd', 0), 'do MMMM yyyy'),
              editComponent: DateStringTableField,
            },
            {
              title: 'Name',
              field: 'name',
            },
            {
              title: 'Rating',
              field: 'starRating',
            },
            {
              title: 'Title',
              field: 'header',
            },
          ]}
          data={reviewItems}
          title={trip.currentVersion.title}
          onRowClick={(_, rowData) => {
            if (rowData) {
              history.push(`/trips/${trip.tripId}/reviews/${rowData.reviewId}`);
            }
          }}
          components={{
            Toolbar: props => (
              <Box p={2} display="flex" flexDirection="row" justifyContent="space-between">
                <Typography gutterBottom variant="h6">
                  {trip.currentVersion.title}
                </Typography>
                <IconButton onClick={() => history.push(`/trips/${trip.tripId}/reviews/add`)}>
                  <AddBox />
                </IconButton>
              </Box>
            ),
          }}
          options={{
            paging: false,
            search: false,
            actionsColumnIndex: -1,
          }}
        />
      )}
    </LoadingGuard>
  );
};
