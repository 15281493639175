import { DateString, PaymentStatus } from '@tripr/common';
import { isBefore, isEqual, parseISO } from 'date-fns';
import '../styles.css';

const InstallmentStatusRow = ({ dueDate, status }: { dueDate: DateString; status: PaymentStatus }) => {
  const today = new Date();
  const tempDueDate = parseISO(dueDate);
  const isPastOrToday = isBefore(tempDueDate, today) || isEqual(tempDueDate, today);

  return <p className={isPastOrToday && status === PaymentStatus.Pending ? 'red-text' : ''}>{status}</p>;
};

export default InstallmentStatusRow;
