import { FormControl, FormHelperText } from '@material-ui/core';
import { Image } from '@tripr/common';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useField } from 'react-final-form';
import { useOnce } from '../../utils/Utils';
import { ImageInputValue, ImageInputView } from './ImageInputView';

interface Props {
  name: string;
  size: [number, number];
  uploader(file: File): Promise<Image | 'ERROR'>;
}

export const MyImageInput = (props: Props) => {
  const { input, meta } = useField<Image | 'UPLOADING'>(props.name, { validate: val => (val === 'UPLOADING' ? 'Image is uploading' : undefined) });
  const [state, setSate] = useState<ImageInputValue>({ jobId: '', status: 'empty', image: null });

  useOnce(() => {
    if (input.value && input.value !== 'UPLOADING') {
      setSate({ jobId: '', status: 'finished', image: input.value });
    }
  });

  const changeValue = (value: ImageInputValue) => {
    setSate(value);
    input.onChange(value.image);
    input.onBlur();
  };

  const onDescriptionChange = useCallback(
    (_jobId: string, description: string) => {
      if (state.image) {
        changeValue({ ...state, image: { ...state.image, description } });
      }
    },
    [state],
  );

  const onRemove = useCallback(() => {
    changeValue({ jobId: '', status: 'empty', image: null });
  }, []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length !== 1) {
      alert('Error');
      return;
    }

    const file = acceptedFiles[0];
    setSate({ jobId: '', status: 'in_progress', file, image: null });
    input.onChange('UPLOADING');
    props
      .uploader(file)
      .then(result => {
        if (result === 'ERROR') {
          changeValue({ jobId: '', status: 'error', file, image: null });
        } else {
          changeValue({ jobId: '', status: 'finished', file, image: result });
        }
      })
      .catch(() => {
        changeValue({ jobId: '', status: 'error', file, image: null });
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    disabled: state.status === 'in_progress',
  });

  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <FormControl error={showError}>
      <div {...getRootProps()}>
        <ImageInputView size={props.size} value={state} onDescriptionChange={onDescriptionChange} onRemove={onRemove} isDragActive={isDragActive}>
          <input {...getInputProps()} />
        </ImageInputView>
      </div>
      {showError && <FormHelperText>{JSON.stringify(meta.error || meta.submitError)}</FormHelperText>}
    </FormControl>
  );
};
