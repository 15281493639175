import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

export default function useConfirmationDialog(props: { title: string; content: React.ReactNode; callback?: () => void }): [() => void, () => React.ReactNode] {
  const [open, setOpen] = React.useState(false);

  const showDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYesClick = () => {
    if (props.callback) props.callback();
    setOpen(false);
  };

  const renderDialog = () => (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default">
          Cancel
        </Button>
        <Button onClick={handleYesClick} color="secondary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );

  return [showDialog, renderDialog];
}
