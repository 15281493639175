import { Box, Grid } from '@material-ui/core';
import { DateString } from '@tripr/common';
import arrayMutators from 'final-form-arrays';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Field, Form } from 'react-final-form';
import * as yup from 'yup';
import { StringSchema } from 'yup';
import { MyDateField } from '../common/forms/DateField';
import { MyTextField } from '../common/forms/TextField';
import { yupValidate } from '../common/forms/YupValidate';
import { SubmitButton } from '../common/SubmitButton';

const ReviewSchema = yup.object({
  name: yup.string().required(),
  starRating: yup.number().required().min(1).max(5),
  date: yup.string<DateString>().required() as StringSchema<DateString>,
  header: yup.string().required(),
  content: yup.string().required(),
});

export type ReviewValues = yup.InferType<typeof ReviewSchema>;
const validator = yupValidate(ReviewSchema);
const InitialValues: ReviewValues = ReviewSchema.cast();

export const ReviewForm: React.FC<{ review?: ReviewValues; onSubmit(values: ReviewValues): Promise<void> }> = ({ review, onSubmit }) => {
  const snackbar = useSnackbar();

  return (
    <div>
      <Form<ReviewValues>
        initialValues={review || InitialValues}
        validate={validator}
        subscription={{}}
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators,
        }}
        onSubmit={values => onSubmit(ReviewSchema.cast(values))}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box maxWidth={640}>
              <Grid container spacing={2}>
                <Field component={MyTextField} name="name" label="Customer name" xs={5} />
                <Field component={MyTextField} name="starRating" label="Star Rating" xs={3} type="number" />
                <Field component={MyDateField} name="date" label="Review date" xs={4} />
                <Field component={MyTextField} name="header" label="Title" xs={12} />
                <Field component={MyTextField} name="content" label="Review text" xs={12} multiline />

                <SubmitButton caption="Save review" handleSubmit={handleSubmit} />
              </Grid>
            </Box>
          </form>
        )}
      />
    </div>
  );
};
