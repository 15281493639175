import React from 'react';
import { AuthContext, authManager } from '../api/Firebase';
import { NoProps } from './Utils';

const defaultAuthContext: AuthContext = { isLoading: true, isLoggedIn: false };

const AuthContextItem = React.createContext<AuthContext>(defaultAuthContext);

export const useAuthContext = () => React.useContext(AuthContextItem);

export class AuthContextProvider extends React.Component<NoProps, AuthContext> {
  public state: AuthContext = defaultAuthContext;
  private subscription: null | (() => void) = null;

  public componentDidMount(): void {
    this.subscription = authManager.onAuthChanged(a => this.setState(a));
  }

  public componentWillUnmount(): void {
    if (this.subscription) {
      this.subscription();
    }
  }

  public render(): React.ReactNode {
    if (this.state.isLoading) {
      return <div>Signing in...</div>;
    }
    return <AuthContextItem.Provider value={this.state}>{this.props.children}</AuthContextItem.Provider>;
  }
}
