import { FormControl, FormHelperText, Grid, GridSize, InputLabel, MenuItem } from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';
import { default as MuiSelect, SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import { FieldProps, getIn } from 'formik';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';

export function SelectOptions(map: { [key: string]: string }): React.ReactNode[] {
  return Object.entries(map).map(pair => (
    <MenuItem key={pair[0]} value={pair[0]}>
      {pair[1]}
    </MenuItem>
  ));
}

interface FormHelperTextWrapperProps extends FieldRenderProps<string> {
  label: string;
  formControlProps: FormControlProps;
  options: { [key: string]: string };
  nullable?: boolean;
  xs: GridSize;
}

export const MySelect: React.FC<FormHelperTextWrapperProps> = ({
  input: { name, value, onChange, ...restInput },
  meta,
  label,
  formControlProps,
  options,
  nullable,
  xs,
  children,
  ...rest
}) => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <Grid item xs={xs}>
      <FormControl {...formControlProps} error={showError} fullWidth>
        <InputLabel htmlFor={name}>{label}</InputLabel>

        <MuiSelect {...rest} name={name} onChange={onChange} inputProps={restInput} value={value}>
          {nullable && (
            <MenuItem key={'(NULL)'} value={null as any}>
              &nbsp;
            </MenuItem>
          )}
          {SelectOptions(options)}
        </MuiSelect>

        {showError && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

export type SelectProps = FieldProps & Omit<MuiSelectProps, 'name' | 'value'> & { options: { [key: string]: string } };

export const MySelect2: React.FC<SelectProps> = props => {
  const { disabled, field, label, form, options, ...otherProps } = props;

  const fieldError = getIn(form.errors, field.name);
  const showError = getIn(form.touched, field.name) && !!fieldError;

  return (
    <FormControl error={showError} fullWidth>
      <InputLabel>{label}</InputLabel>
      <MuiSelect disabled={form.isSubmitting} {...otherProps} {...field}>
        {SelectOptions(options)}
      </MuiSelect>
      <FormHelperText>{showError ? fieldError : ''}</FormHelperText>
    </FormControl>
  );
};
