import { Grid } from '@material-ui/core';
import { RecommendedTrip, TripId } from '@tripr/common';
import React from 'react';
import { Field } from 'react-final-form';
import { FormList } from '../../common/FormList';
import { MySelect } from '../../common/forms/MySelect';

export const RecommendedTripList: React.FC<{ name: string; tripOptions: { [key: string]: string } }> = ({ name, tripOptions }) => (
  <FormList<RecommendedTrip>
    name={name}
    newValue={() => ({ tripId: TripId('') })}
    render={p => (
      <Grid container spacing={1}>
        <Field component={MySelect} name={`${p.parentName}.tripId`} label="Trip" xs={12} options={tripOptions} />
      </Grid>
    )}
  />
);
