import * as contentful from 'contentful';

export interface BlogCategory {
  name: string;
  slug: string;
  children: ReadonlyArray<contentful.Entry<BlogCategory>>;
}

export type BlogCategoryEntry = contentful.Entry<BlogCategory>;

export class ContentfulApiClient {
  private static readonly client = contentful.createClient({
    space: '3ja0qb1g0ubk',
    accessToken: 'S-8k4C6GPtyykaL2FEKZHfHuqh59uz5m_seu79jALw0',
  });

  public static async GetCategories(): Promise<ReadonlyArray<BlogCategoryEntry>> {
    const collection = await ContentfulApiClient.client.getEntries<BlogCategory>({
      content_type: 'blogCategory',
      include: 5,
    });

    return collection.items;
  }
}
