import { setIn } from 'final-form';
import * as yup from 'yup';

export const yupValidate =
  <T extends Record<string, unknown>>(schema: yup.ObjectSchema<T>) =>
  (values: T): Partial<{ [key in keyof T]: string }> => {
    try {
      schema.validateSync(values, { abortEarly: false });
      return {};
    } catch (err) {
      const e = err as yup.ValidationError;
      return e.inner.reduce((errors, error) => setIn(errors, error.path, error.message), {});
    }
  };
