import { format, parseISO } from 'date-fns';
import MaterialTable from 'material-table';
import React, { useState } from 'react';
import { ReservationApi, TripWithVersionApi } from '../../api/RpcClient';
import { LoadingGuard } from '../common/LoadingGuard';
import { Button } from '@material-ui/core';
import { ReservationId, ReservationStatus } from '@tripr/common';
import { useHistory } from 'react-router-dom';
import useConfirmationDialog from '../common/UseConfirmationDialog';

export const ReservationsListPage: React.FC = () => {
  const history = useHistory();
  const [cancelId, setCancelId] = useState<ReservationId | null>(null);

  const handleCancelClick = async (id: ReservationId) => {
    setCancelId(id);
    showDialog();
  };

  const modalCallback = async () => {
    if (!cancelId) return;
    await ReservationApi.cancelReservation(cancelId);
    history.go(0);
  };

  const [showDialog, renderDialog] = useConfirmationDialog({
    title: 'Cancel Reservation?',
    content: <p>Are you sure you want to cancel this reservation?</p>,
    callback: modalCallback,
  });

  return (
    <LoadingGuard
      loadData={async () => {
        return {
          reservations: await ReservationApi.getReservations(),
          tripOptions: await TripWithVersionApi.getTripOptions({ includeSlug: false }),
        };
      }}
    >
      {({ reservations, tripOptions }) => (
        <div>
          <MaterialTable
            columns={[
              { title: 'Reservation ID', field: 'reservationId' },
              {
                title: 'Customer',
                field: 'firstName',
                render: row => (
                  <div>
                    <b>
                      {row.firstName} {row.lastName}
                    </b>
                    <br />
                    {row.email}
                    <br />
                    {row.phoneNumber}
                  </div>
                ),
              },
              {
                hidden: true,
                field: 'lastName',
                searchable: true,
              },
              {
                title: 'Trip',
                render: row =>
                  row.reservationType === 'regular' ? (
                    <div>
                      <b>{row.people}</b> person on <b>{format(parseISO(row.tripDate), 'd MMM yy')}</b>
                      <br />
                      <a href={`/trips/${row.tripId}/edit`}> {tripOptions[row.tripId]}</a>
                    </div>
                  ) : (
                    <div>
                      <b>Itinerary only</b>
                      <br />
                      <a href={`/trips/${row.tripId}/edit`}> {tripOptions[row.tripId]}</a>
                    </div>
                  ),
              },
              {
                title: 'Price - discount (coupon)',
                align: 'center',
                field: 'couponCode',
                render: row =>
                  row.discount ? (
                    <div>
                      ${row.totalPrice + row.discount} - ${row.discount} = <b>${row.totalPrice}</b>
                      <br />
                      <br />
                      {row.couponCode}
                    </div>
                  ) : (
                    <b>${row.totalPrice}</b>
                  ),
              },

              { title: 'Reserved at', type: 'datetime', field: 'createdAt', render: d => format(parseISO(d.createdAt), 'd MMM yy HH:mm') },
              { title: 'Status', type: 'string', field: 'status' },
              {
                title: '',
                render: row =>
                  row.status != ReservationStatus.Canceled ? (
                    <Button size="small" variant="contained" color="primary" onClick={() => handleCancelClick(row.reservationId)}>
                      Cancel
                    </Button>
                  ) : (
                    ''
                  ),
              },
            ]}
            data={reservations}
            title="Reservations"
            options={{
              pageSize: 20,
              actionsColumnIndex: -1,
            }}
          />
          {renderDialog()}
        </div>
      )}
    </LoadingGuard>
  );
};
