import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { useHistory } from 'react-router-dom';
import { authManager } from '../../api/Firebase';
import cover from '../../images/cover.jpg';
import logo from '../../images/logoWhite.png';
import { useAuthContext } from '../../utils/AuthContext';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  logo: {
    position: 'absolute',
    top: 20,
    left: 20,
  },
  image: {
    backgroundImage: `url(${cover})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {},
}));

export const SigninPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const authContext = useAuthContext();
  if (authContext.isLoggedIn) {
    history.push('/trips');
    return <div>Redirecting...</div>;
  }
  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box p={2} className={classes.paper}>
          <StyledFirebaseAuth {...authManager.getFirebaseUiProps()} />
        </Box>
      </Grid>
      <div className={classes.logo}>
        <img src={logo} width={150} />
      </div>
    </Grid>
  );
};
