import { Account, Image, RpcReadResponse, UploadedImage } from '@tripr/common';
import { Configuration } from './Config';
import { authManager } from './Firebase';

interface ApiResponse<T> {
  result: T;
}

export const makeApiCall = async <T>(
  method: 'GET' | 'POST',
  path: string,
  requestBody: BodyInit | null = null,
  contentType = 'application/json',
): Promise<T> => {
  const token = await authManager.getToken();
  if (!token) {
    throw new Error('Not authenticated');
  }

  const url = `${Configuration.ApiUrl}/${path}`;

  const response = await fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...(contentType ? { 'Content-Type': contentType } : {}),
    },
    body: requestBody,
  });

  if (response.ok) {
    return response.json();
  }
  throw new Error('Network call error');
};

export class ImagesApi {
  public async uploadImage(bucket: string, file: Blob | string): Promise<UploadedImage> {
    const formData = new FormData();
    formData.append('file', file);
    const response = await makeApiCall<ApiResponse<UploadedImage>>('POST', `Images/${bucket}`, formData, '');
    return response.result;
  }
}

export const GetImageUrl = (image: Image | string, width: number, height: number) =>
  typeof image === 'string' ? image : `${Configuration.ImagesCdnUrl}/jpg_${width}_${height}/${image.hash}.jpg`;

export const GetImageUrlFullSize = (imageHash: string) => `${Configuration.ImagesCdnUrl}/trip/${imageHash}.jpg`;

export class AuthApi {
  public async getOrCreate(): Promise<RpcReadResponse<Account>> {
    return makeApiCall<RpcReadResponse<Account>>('POST', `Auth/getOrCreate`, '');
  }
}
