import { Box, Grid, Typography } from '@material-ui/core';
import { CouponId, DateString, DiscountSpec, DiscountType, DiscountTypeOptions, DiscountTypes, Money, ReservationTypes, TripId } from '@tripr/common';
import arrayMutators from 'final-form-arrays';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Field, Form } from 'react-final-form';
import * as yup from 'yup';
import { NumberSchema, StringSchema } from 'yup';
import { FormList } from '../common/FormList';
import { MyDateField } from '../common/forms/DateField';
import { MySelect } from '../common/forms/MySelect';
import { BooleanField, MyTextField } from '../common/forms/TextField';
import { yupValidate } from '../common/forms/YupValidate';
import { SubmitButton } from '../common/SubmitButton';

const CouponSchema = yup.object({
  couponId: yup.string<CouponId>().required() as StringSchema<CouponId>,
  title: yup.string().required(),
  validAfter: yup.string<DateString>().nullable() as StringSchema<DateString | null>,
  validBefore: yup.string<DateString>().nullable() as StringSchema<DateString | null>,
  reservationTypes: yup.object<ReservationTypes>().required().noUnknown().default({ regular: false, itineraryOnly: false }).shape({
    regular: yup.boolean().required(),
    itineraryOnly: yup.boolean().required(),
  }),
  onlyForEmails: yup.array<string>().default([]).of(yup.string().email().required()),
  onlyForTrips: yup
    .array<TripId>()
    .default([])
    .of(yup.string().required() as StringSchema<TripId>),
  discountSpec: yup
    .object<DiscountSpec>()
    .required()
    .noUnknown()
    .default({ discountType: 'amount' })
    .shape({
      discountType: yup.string().required().oneOf(DiscountTypes) as StringSchema<DiscountType>,
      amountOff: yup.number().nullable().min(1) as NumberSchema<Money>,
      percentOff: yup.number().nullable().min(1).max(100),
    }),
  minPurchaseAmount: yup.number().nullable().min(1) as NumberSchema<Money | null>,
  totalUsageLimit: yup.number().nullable().min(1),
  perCustomerLimit: yup.number().nullable().min(1),
});

export type CouponValues = yup.InferType<typeof CouponSchema>;
const validator = yupValidate(CouponSchema);
const InitialValues: CouponValues = CouponSchema.cast();

export const OnlyForTripList: React.FC<{ name: string; tripOptions: { [key: string]: string } }> = ({ name, tripOptions }) => (
  <FormList<TripId>
    name={name}
    newValue={() => TripId('')}
    render={p => (
      <Grid container spacing={1}>
        <Field component={MySelect} name={`${p.parentName}`} label="Trip" xs={12} options={tripOptions} />
      </Grid>
    )}
  />
);
export const OnlyForEmailList: React.FC<{ name: string }> = ({ name }) => (
  <FormList<TripId>
    name={name}
    newValue={() => TripId('')}
    render={p => (
      <Grid container spacing={1}>
        <Field component={MyTextField} name={`${p.parentName}`} label="Email" xs={12} />
      </Grid>
    )}
  />
);

export const CouponForm: React.FC<{ coupon?: CouponValues; onSubmit(values: CouponValues): Promise<void>; tripOptions: { [key: string]: string } }> = ({
  coupon,
  onSubmit,
  tripOptions,
}) => {
  const snackbar = useSnackbar();

  return (
    <div>
      <Form<CouponValues>
        initialValues={coupon || InitialValues}
        validate={validator}
        subscription={{}}
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators,
        }}
        onSubmit={values => {
          const clean = CouponSchema.cast(values);
          if (!(clean.reservationTypes.regular || clean.reservationTypes.itineraryOnly)) {
            snackbar.enqueueSnackbar(`At least one reservation type should be selected`, { variant: 'error' });
            return;
          }
          if (clean.discountSpec.discountType === 'amount') {
            if (!clean.discountSpec.amountOff) {
              snackbar.enqueueSnackbar(`Amount off could not be empty when discount type is "Amount off"`, { variant: 'error' });
              return;
            }
            clean.discountSpec.percentOff = null;
          } else {
            if (!clean.discountSpec.percentOff) {
              snackbar.enqueueSnackbar(`Percent off could not be empty when discount type is "Percent off"`, { variant: 'error' });
              return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            clean.discountSpec.amountOff = null;
          }

          clean.validAfter = clean.validAfter || null;
          clean.validBefore = clean.validBefore || null;
          clean.minPurchaseAmount = clean.minPurchaseAmount || null;
          clean.totalUsageLimit = clean.totalUsageLimit || null;
          clean.perCustomerLimit = clean.perCustomerLimit || null;
          return onSubmit(clean);
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box maxWidth={640}>
              <Grid container spacing={2}>
                <Field component={MyTextField} name="couponId" label="Coupon code" xs={4} capitalize={true} />
                <Field component={MyTextField} name="title" label="Description" xs={12} />

                <Field component={BooleanField} name="reservationTypes.regular" label="Regular reservation" xs={6} />
                <Field component={BooleanField} name="reservationTypes.itineraryOnly" label="Itinerary Only" xs={6} />

                <Field component={MySelect} name="discountSpec.discountType" label="Discount type" xs={4} options={DiscountTypeOptions} />
                <Field component={MyTextField} name="discountSpec.amountOff" label="Amount off" xs={4} type="number" nullable="true" />
                <Field component={MyTextField} name="discountSpec.percentOff" label="Percent off" xs={4} type="number" nullable="true" />

                <Field component={MyTextField} name="totalUsageLimit" label="Total usage limit" xs={4} type="number" nullable="true" />
                <Field component={MyTextField} name="perCustomerLimit" label="Per customer limit" xs={4} type="number" nullable="true" />
                <Field component={MyTextField} name="minPurchaseAmount" label="Min purchase amount" xs={4} type="number" nullable="true" />

                <Field component={MyDateField} name="validAfter" label="Valid after" xs={6} />
                <Field component={MyDateField} name="validBefore" label="Valid before" xs={6} />

                <Grid item xs={12}>
                  <Typography variant={'h4'}>Only for trips</Typography>
                  <OnlyForTripList name="onlyForTrips" tripOptions={tripOptions} />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant={'h4'}>Only for customer emails</Typography>
                  <OnlyForEmailList name="onlyForEmails" />
                </Grid>

                <SubmitButton caption="Save coupon" handleSubmit={handleSubmit} />
              </Grid>
            </Box>
          </form>
        )}
      />
    </div>
  );
};
