import { Typography } from '@material-ui/core';
import { CouponId, Coupon } from '@tripr/common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CouponApi, TripWithVersionApi } from '../../api/RpcClient';
import { useToast } from '../../utils/Utils';
import { LoadingGuard } from '../common/LoadingGuard';
import { CouponForm, CouponValues } from './CouponForm';

export const CouponEditPage: React.FC = () => {
  const params = useParams<{ couponId: CouponId }>();
  const snackbar = useSnackbar();
  const [callWithToast] = useToast('coupon');

  return (
    <LoadingGuard
      loadData={async () => {
        const coupon = await CouponApi.getCoupon(params.couponId as CouponId);
        const tripOptions = await TripWithVersionApi.getTripOptions();
        return { coupon, tripOptions };
      }}
    >
      {({ coupon, tripOptions }) => (
        <div>
          <Typography gutterBottom variant="h2">
            {coupon.couponId}
          </Typography>

          <CouponForm
            coupon={coupon as CouponValues}
            tripOptions={tripOptions}
            onSubmit={async (values: CouponValues) => {
              if (values.couponId !== coupon.couponId) {
                snackbar.enqueueSnackbar(`Changing coupon code is not allowed`, { variant: 'error' });
              } else {
                const newCoupon: Coupon = { ...values, createdAt: undefined };

                await callWithToast(CouponApi.updateCoupon(newCoupon));
              }
            }}
          />
        </div>
      )}
    </LoadingGuard>
  );
};
